(function ($) {
    "use strict";

    $(document).ready(function(){
        // $('.partially').mousemove(function (e) {
        //     $('.edit-actions', this).css({left: e.pageX - 100, top: e.pageY + 10});
        // });
    });
      


})(jQuery);